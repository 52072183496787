import { useMutation, useQuery } from '@vue/apollo-composable'
import { gql } from '@apollo/client'

export function getList({ pageId, page, pageSize }: { pageId: string; page: number; pageSize: number }) {
  const query = gql`
      query getLinks($pageId: ID!, $page: Int!, $pageSize: Int!) {
        links(pagination: { page: $page, pageSize: $pageSize }, filters: { page: { id: { eq: $pageId } } }, sort: ["createdAt:desc"]) {
          data{
            id
            attributes {
              recipient_name
              recipient_domain
              thumbnail
              metadata
            }
          }
          meta{
            pagination{
                total
                pageCount
            }
          }
        }
      }
    `
  const variables = { page, pageSize, pageId }
  return useQuery(query, variables, { prefetch: false })
}

export function getDetail(id: string) {
  const query = gql`
      query getLink($id: ID!) {
        link(id: $id) {
          data{
            id
            attributes {
              page {
                data {
                  id
                  attributes {
                    Title
                    Description
                  }
                }
              }
              owner {
                data {
                  id
                  attributes {
                    email
                    username
                  }
                }
              }
              recipient_name
              recipient_domain
              thumbnail
              metadata
            }
          }
        }
      }
    `
  const variables = { id }
  return useQuery(query, variables, { prefetch: false })
}

export function createNew({ pageId, page_name, recipient_name, recipient_domain, thumbnail, metadata }: { pageId: string; page_name: string; recipient_name: string; recipient_domain: string; thumbnail: string; passcode: string; metadata?: INewLinkMetadata }) {
  const { account } = useUserStore()
  const mutation = gql`
      mutation createLink ($pageId: ID!, $page_name: String, $recipient_name: String, $recipient_domain: String, $thumbnail: String, $metadata: JSON, $publishedAt: DateTime, $userId: ID!){
        createLink(data: { page: $pageId, page_name: $page_name, recipient_name: $recipient_name, recipient_domain: $recipient_domain, thumbnail: $thumbnail, metadata: $metadata, publishedAt: $publishedAt, owner: $userId }) {
          data{
            id
            attributes {
              page {
                data {
                  id
                  attributes {
                    Title
                    Description
                  }
                }
              }
              page_name
              recipient_name
              recipient_domain
              thumbnail
            }
          }
        }
      }
    `
  const variables = { pageId, page_name, recipient_name, recipient_domain, thumbnail, metadata, publishedAt: new Date(), userId: account?.id }
  return useMutation<{ createLink: { data: ILink } }>(mutation, { variables })
}

export function updateDetailByPasscode({ id, passcode }: { id: string; passcode: string }) {
  const mutation = gql`
      mutation updateLink ($id: ID!, $passcode: String ){
        updateLink(id: $id, data: { passcode: $passcode }) {
          data{
            id
            attributes {
              page {
                data {
                  id
                  attributes {
                    Title
                    Description
                  }
                }
              }
              recipient_name
              recipient_domain
              thumbnail
            }
          }
        }
      }
    `
  const variables = { id, passcode }
  return useMutation(mutation, { variables })
}

export function checkExistedDetail({ pageId, recipient_name, recipient_domain }: { pageId: string; recipient_name: string; recipient_domain: string }) {
  const query = gql`
      query getLink($pageId: ID!, $recipient_name: String, $recipient_domain: String) {
        links(filters: { page: { id: { eq: $pageId } }, recipient_name: { eq: $recipient_name }, recipient_domain: { eq: $recipient_domain } }) {
          data{
            id
            attributes {
              page {
                data {
                  id
                  attributes {
                    Title
                    Description
                  }
                }
              }
              page_name
              recipient_name
              recipient_domain
              thumbnail
            }
          }
        }
      }
    `
  const variables = { pageId, recipient_name, recipient_domain }
  return useQuery<{ links: { data: ILink[] } }>(query, variables, { prefetch: false })
}
